import modeOfSale from './getModeOfSale.js'

// Page URL
const pageURL = window.location.href

// Test Mode
const testMode = (pageURL.includes('test.tnhs.cloud'))
console.log('test mode', testMode)

// Register
if (pageURL.includes('account/create')) {
  const form = document.getElementById('tn-account-register-form')
  const subscribeComponent = document.createElement('u-30-subscribe')
  subscribeComponent.classList.add('col-xs-12')
  subscribeComponent.dataset.test = testMode
  form.lastElementChild.prepend(subscribeComponent)

  const registerButton = document.getElementById('tn-account-register-button-register')
  registerButton.style.maxWidth = '452px'
}

// Events
if (pageURL.includes('events')) {
  // Elements
  const eventsHeader = document.getElementsByClassName('tn-header-component')[0]

  // Signed Up Component
  const signedUpComponent = document.createElement('u-30-signed-up')
  signedUpComponent.setAttribute('data-customer-id', modeOfSale.customerId)
  signedUpComponent.dataset.test = testMode
  eventsHeader.append(signedUpComponent)
}

// Cart Details
if (pageURL.includes('cart/details')) {
  // Elements
  const eventsHeader = document.getElementsByClassName('tn-header-component')[0]

  // Signed Up Component
  const signedUpComponent = document.createElement('u-30-signed-up')
  signedUpComponent.setAttribute('data-customer-id', modeOfSale.customerId)
  signedUpComponent.dataset.test = testMode
  eventsHeader.append(signedUpComponent)
}

// Account Details
if (pageURL.includes('account/update')) {
  // Elements
  const accountHeader = document.getElementsByClassName('tn-header-component')[0]
  const form = document.getElementById('tn-update-form')

  // Signed Up Component
  const signedUpComponent = document.createElement('u-30-signed-up')
  signedUpComponent.setAttribute('data-customer-id', modeOfSale.customerId)
  signedUpComponent.dataset.test = testMode
  accountHeader.append(signedUpComponent)

  // Subscribe Component
  const subscribeComponent = document.createElement('u-30-subscribe')
  subscribeComponent.setAttribute('data-customer-id', modeOfSale.customerId)
  subscribeComponent.dataset.standalone = true
  subscribeComponent.dataset.test = testMode
  form.lastElementChild.prepend(subscribeComponent)
}

// Account Memberships
if (pageURL.includes('account/memberships')) {
  // Elements
  const membershipsPage = document.getElementsByClassName('tn-page-layout__page-header-container')[0]

  // Unsubscribe Component
  const unsubscribeComponent = document.createElement('u-30-unsubscribe')
  unsubscribeComponent.setAttribute('data-customer-id', modeOfSale.customerId)
  unsubscribeComponent.dataset.test = testMode
  membershipsPage.append(unsubscribeComponent)

  const noMembershipsBlock = document.getElementsByClassName('tn-empty-cart-message')[0]
  noMembershipsBlock.style.display = 'none'
}
