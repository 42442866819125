import './van11y-accessible-accordion-aria.es6.js'
import modeOfSale from './getModeOfSale.js'

const giftAidUpsell = `
  <section class="c-accordion-upsell">
      <div class="c-accordion-upsell__inner-wrapper">
          <h2>Supporting the Gallery</h2>
          <p>Adding a donation to your ticket today will provide us with vital support.</p>
          <p>If you’re a UK taxpayer, you can Gift Aid your ticket when completing payment, and we’ll be able to claim 25p for every £1 of your ticket price from HMRC. Thank you.</p>
      </div>
  </section>`

function appendAccordionToPriceList () {
  // Look for each list of ticket types
  const priceList = document.querySelectorAll('.tn-ticket-selector__pricetype-container')
  // and then add a panel below
  priceList.forEach(item => {
    const accordionContainer = document.createElement('div')
    accordionContainer.classList.add('js-accordion')
    item.appendChild(accordionContainer)
  })
}

function addContentToAccordion () {
  // Define the accordion markup to be added to each of the panels
  const memberAccordion = `
    <div class="js-accordion" data-accordion-prefix-classes="c">
        <h2 class="js-accordion__header">
            <div class="js-accordion__text-container">
                <span class="js-accordion__text-heading">See more options</span>
                <span class="js-accordion__text-subheading">Click to expand</span>
            </div>
        </h2>
        <div class="js-accordion__panel">
            ${giftAidUpsell}
            <ul class="list-unstyled">
            </ul>
        </div>
    </div>
    `
  // Add the accordion container to each of the price lists
  const accordionTarget = document.querySelectorAll('.js-accordion')

  accordionTarget.forEach(item => {
    item.innerHTML = memberAccordion
  })
}

let listOfContainers = []

function getListOfContainers () {
  // Add each price zone ID (each timed entry slot) into array
  const container = document.querySelectorAll('.tn-ticket-selector__pricetype-container')
  container.forEach(element => {
    listOfContainers.push(element.dataset.zoneId)
  })
  return listOfContainers
}

function appendPriceTypes (zoneId) {
  // For each zone find the accordion element
  const accordionElement = document.querySelector(`.tn-ticket-selector__pricetype-container[data-zone-id="${zoneId}"] .js-accordion__panel ul`)

  // Get each price type that is NOT of the following categories
  const ticketTypesNodeList = document.querySelectorAll(
    `.tn-ticket-selector__pricetype-container[data-zone-id="${zoneId}"] .tn-ticket-selector__pricetype` +
        `:not([data-tn-price-type-id="9"])` // Adult with donation
  )

  // Create document fragment, and add each of the ticket types to the fragment
  const fragment = new DocumentFragment()
  const ticketTypesArray = Array.prototype.slice.call(ticketTypesNodeList)
  ticketTypesArray.forEach(element => {
    fragment.append(element.parentElement)
  })
  // append the fragment to the accordion panel
  accordionElement.append(fragment)
}

// If event type is 'Exhibition' do the following
if (modeOfSale.productTypeDescription === 'Exhibition') {
  // Run each of the functions
  appendAccordionToPriceList()
  addContentToAccordion()
  getListOfContainers()

  // For each timed entry slot, add the price types to the accordion
  for (let i = 0; i < listOfContainers.length; i++) {
    appendPriceTypes(listOfContainers[i])
  }
} else {
  // Do nothing
}
