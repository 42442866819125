import modeOfSale from './getModeOfSale.js'

// Selects page with ticket zone
const ticketZone = document.querySelector('.tn-ticket-selector__zone-selector')

const priceTypeSelector = document.querySelector('.tn-ticket-selector__zone-selector-container')

// Selects cart details page
const cartDetails = document.querySelector('.tn-cart-details-page')

// Get return url for login from window
const urlPathName = window.location.pathname
const returnUrlString = `?returnurl=${encodeURIComponent(urlPathName)}`

// If page has ticket zone class or cart details class
if ((ticketZone) || (cartDetails)) {
  // and if not logged in show member cards
  if (modeOfSale.customerId === 0) {
    const memberSignup = `
        <section class="c-panel-card c-panel-card--friends">
          <div class="c-panel-card__inner-wrapper">
            <h2>Friends enjoy free access to all our exhibitions</h2>
            <p>Log in to book Friends tickets.</p>
            <div class="c-panel-card__btn-container">
              <a href="https://www.dulwichpicturegallery.org.uk/join-support/friends-patrons/join-the-friends" class="btn btn-primary">
                Become a Friend
              </a>
              <a href="/account/login${returnUrlString}" data-account-url="/account/login" class="btn btn-default">
                  Members Login
              </a>
            </div>
          </div>
        </section>

        <section class="c-panel-card c-panel-card--under-30">
          <div class="c-panel-card__inner-wrapper">
            <h2>50% off exhibition tickets for 18-30s</h2>
            <div class="c-panel-card__btn-container">
              <a href="https://www.dulwichpicturegallery.org.uk/planning-your-visit/under-30s/" class="btn btn-primary">
                Join Under 30s
              </a>
              <a href="/account/login${returnUrlString}" data-account-url="/account/login" class="btn btn-default">
                Log in
              </a>
            </div>
          </div>
        </section>
      `
    priceTypeSelector.insertAdjacentHTML('beforebegin', memberSignup)
    console.log('member not logged in')
  }
}
