import modeOfSale from './getModeOfSale.js'

const assetUrl = 'https://dulwichpicturegallery-tnew-template.s3-eu-west-2.amazonaws.com/qa/img/'
const priceTypeSelector = document.querySelector('.tn-ticket-selector__pricetype-selector')

const giftAidPanel = `
  <section class="c-panel-card c-panel-card--giftaid">
      <div class="c-panel-card__image">
          <img src="${assetUrl}gift-aid-logo.png" alt="Gift aid wordmark"/>
      </div>
      <div class="c-panel-card__inner-wrapper">
          <h2>Supporting the Gallery</h2>
          <p>Adding a donation to your ticket today will provide us with vital support.</p>
          <p>If you’re a UK taxpayer, you can Gift Aid your ticket when completing payment, and we’ll be able to claim 25p for every £1 of your ticket price from HMRC. Thank you.</p>
      </div>
  </section>`

// Only add gift aid upsell on events with the type of 'Exhibtion'
// Add markup below the pricelist
if (modeOfSale.productTypeDescription === 'Exhibition') {
  priceTypeSelector.insertAdjacentHTML('afterend', giftAidPanel)
}
