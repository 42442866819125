/* eslint-disable no-unused-vars */
import u30Customisations from './u-30-customisations'
import memberU30Signup from './member-signup.js'
import './pricesAccordion.js'
import './gift-aid-upsell'

$(() => {
  console.log('Customisations v0.1.5')

  // Move gift aid panel in Cart page to
  $('#tn-customisation-gift-aid').prependTo('.tn-donation-ask-component')
})
